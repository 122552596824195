// index.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select, Button } from "antd";

import PatientSearch from "./Components/PatientSearch";
import ColumnFilters from "./Components/ColumnFilters";
import TreatmentTable from "./Components/TreatmentTable";
import UpdatePatient from "./Components/UpdatePatient";
import {
  DropdownCellRenderer,
  CheckboxCellRenderer,
  NoteCellRenderer,
  QuantityCellRenderer,
  CheckboxHeader,
  DateCellRenderer,
} from "./Components/CellRenderers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TreatmentView = () => {
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState("all");
  const [allColumnDefs, setAllColumnDefs] = useState({});

  const handleSearch = async (value) => {
    if (value) {
      try {
        const response = await axios.get(
          `/api/patients/search?query=${value}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
        setPatients(response.data);
      } catch (error) {
        console.error("Error fetching patients:", error);
      }
    } else {
      setPatients([]);
    }
  };

  const handleSelectPatient = async (patientId) => {
    try {
      const patientResponse = await axios.get(
        `/api/patients/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setSelectedPatient(patientResponse.data);
      toast.success(
        `Patient ${patientResponse.data["Full Name"]} selected successfully`
      );
      await fetchTreatments(patientResponse.data["Customer ID"]);
    } catch (error) {
      console.error("Error fetching patient data:", error);
      toast.error(`Failed to fetch patient data: ${error.message}`);
    }
  };

  const handleUpdateOrder = async (orderId, updateInfo, newValue) => {
    try {
      const url = `/api/patient-orders/${orderId}`;
      const payload = {
        type: updateInfo.type,
        valueType: updateInfo.valueType,
        name: updateInfo.name,
        value: newValue,
      };

      const response = await axios.put(url, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });

      if (response.status === 200) {
        console.log(`Order ${orderId} updated successfully`);
        let type = updateInfo.name || updateInfo.valueType;
        toast.success(`${type} updated successfully`);
        // Optionally, you can update the local state here if needed
      } else {
        throw new Error(`Failed to update order ${orderId}`);
      }
    } catch (error) {
      console.error("Error updating order:", error);

      // Check if the error response contains the specific message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Error updating order" &&
        error.response.data.error ===
          "You cannot modify this as it has been finalized."
      ) {
        toast.error("This order has been finalized and cannot be modified.", {
          icon: "🔒",
        });
      } else {
        // For all other errors
        toast.error(
          `Failed to update order: ${
            error.response?.data?.error || error.message
          }`
        );
      }
    }
  };

  const createNewOrder = async (type) => {
    if (!selectedPatient) {
      toast.error("Please select a patient first");
      return;
    }

    try {
      const response = await axios.post(
        `/api/patient/order/new/${selectedPatient["Customer ID"]}/${type}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success(`New ${type.toLowerCase()} created successfully`);
        //Append treatment to the row data
        const newRowData = [
          ...rowData,
          {
            orderId: response.data._id,
            date: response.data.date,
            customName: response.data.custom || "",
            type: response.data.type || "Order",
            review: response.data.review || false,
            status: response.data.status || "",
            assignees: response.data.assignees || [],
            room: response.data.room || "",
            note: response.data.note || "",
          },
        ];

        setRowData(newRowData);
        
       
      } else {
        throw new Error(`Failed to create new ${type.toLowerCase()}`);
      }
    } catch (error) {
      console.error(`Error creating new ${type.toLowerCase()}:`, error);
      toast.error(
        `Failed to create new ${type.toLowerCase()}: ${error.message}`
      );
    }
  };

  const handleCellValueChanged = (params) => {
    const orderId = params.data.orderId;
    const columnId = params.column.colId;
    const newValue = params.newValue;

    // Helper function to determine the update type and value type
    const getUpdateInfo = (columnId) => {
      if (columnId.startsWith("procedure_")) {
        const parts = columnId.split("_");
        const valueType = parts[parts.length - 1];
        const procedureName = parts
          .slice(
            1,
            valueType === "note" || valueType === "customDosage"
              ? -1
              : undefined
          )
          .join(" ");
        return {
          type: "userProcedures",
          valueType:
            valueType === "customDosage"
              ? "customDosage"
              : valueType === "note"
              ? "note"
              : typeof newValue === "boolean"
              ? "checkbox"
              : "dosage",
          name: procedureName,
        };
      } else if (columnId.startsWith("lab_")) {
        return {
          type: "userLabs",
          valueType: "checkbox",
          name: columnId.split("_").slice(1).join(" "),
        };
      } else if (columnId.startsWith("dispensed_")) {
        const parts = columnId.split("_");
        const valueType = parts[parts.length - 1];
        const dispensedName = parts
          .slice(
            1,
            valueType === "quantity" || valueType === "note" ? -1 : undefined
          )
          .join(" ");
        return {
          type: "userdispensed",
          valueType:
            valueType === "quantity"
              ? "quantity"
              : valueType === "note"
              ? "note"
              : "dosage",
          name: dispensedName,
        };
      }
      return { type: "common", valueType: columnId };
    };

    const updateInfo = getUpdateInfo(columnId);

    if (updateInfo.type === "common") {
      switch (columnId) {
        case "date":
          const formattedDate = formatDate(newValue);
          console.log(`Order ID: ${orderId}, Updated Date: ${formattedDate}`);
          break;
        case "customName":
        case "type":
        case "review":
        case "status":
        case "assignees":
        case "room":
        case "note":
          console.log(`Order ID: ${orderId}, Updated ${columnId}: ${newValue}`);
          break;
      }
    } else {
      console.log(
        `Order ID: ${orderId}, Type: ${updateInfo.type}, Value Type: ${updateInfo.valueType}, Name: ${updateInfo.name}, New Value: ${newValue}`
      );
    }

    // Call the updateOrder function
    handleUpdateOrder(orderId, updateInfo, newValue);
    // Here you would typically update your backend with the new value
    // updateBackend(orderId, columnId, newValue, updateInfo);
  };

  const fetchTreatments = async (patientId) => {
    try {
      const ordersResponse = await axios.get(
        `/api/patient-orders/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      processOrdersData(ordersResponse.data);
      toast.success("Treatments fetched successfully");
    } catch (error) {
      console.error("Error fetching treatment data:", error);
      toast.error(`Failed to fetch treatments: ${error.message}`);
    }
  };

  const fetchAssigneeOptions = async () => {
    console.log("Fetching assignee options");
    // TODO: Implement API call to fetch assignee options
    // For now, return a dummy array
    return ["Assignee 1", "Assignee 2", "Assignee 3"];
  };

  const fetchRoomOptions = async () => {
    console.log("Fetching room options");
    // TODO: Implement API call to fetch room options
    // For now, return a dummy array
    return ["Room 1", "Room 2", "Room 3"];
  };

  const handleRefresh = async () => {
    if (selectedPatient) {
      await fetchTreatments(selectedPatient["Customer ID"]);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const processOrdersData = (orders) => {
    if (orders.length === 0) return;

    const sampleOrder = orders[0];
    const commonColumns = [
      { field: "orderId", headerName: "Order ID", hide: true },
      {
        field: "date",
        headerName: "Date",
        cellRenderer: DateCellRenderer,
        valueFormatter: (params) => formatDate(params.value),
        minWidth: 160,
      },
      {
        field: "customName",
        headerName: "Custom Name",
        cellRenderer: NoteCellRenderer,
        minWidth: 160,
      },
      {
        field: "type",
        headerName: "Type",
        cellRenderer: DropdownCellRenderer,
        cellRendererParams: { values: ["Order", "Treatment"] },
        minWidth: 160,
      },
      {
        field: "review",
        headerName: "Final",
        cellRenderer: CheckboxCellRenderer,
      },
      {
        field: "status",
        headerName: "Status",
        cellRenderer: DropdownCellRenderer,
        cellRendererParams: { values: ["scheduled", "arrived", "completed"] },
        minWidth: 160,
      },
      {
        field: "assignees",
        headerName: "Assignees",
        cellRenderer: DropdownCellRenderer,
        cellRendererParams: { values: [] }, // We'll populate this later
        minWidth: 160,
      },
      {
        field: "room",
        headerName: "Room",
        cellRenderer: DropdownCellRenderer,
        cellRendererParams: { values: [] }, // We'll populate this later
        minWidth: 160,
      },
      {
        field: "note",
        headerName: "Note",
        cellRenderer: NoteCellRenderer,
        minWidth: 160,
      },
    ];

    const procedureColumns = sampleOrder.userProcedures.map((procedure) => {
      const baseColumn = {
        headerName: procedure.procedure_name,
        children: [
          {
            field: `procedure_${procedure.procedure_name.replace(/\s+/g, "_")}`,
            headerName: "Dosage",
            headerComponent:
              procedure.dosages && procedure.dosages.length > 0
                ? undefined
                : CheckboxHeader,
            headerComponentParams:
              procedure.dosages && procedure.dosages.length > 0
                ? undefined
                : {},
            cellRenderer:
              procedure.dosages && procedure.dosages.length > 0
                ? DropdownCellRenderer
                : CheckboxCellRenderer,
            cellRendererParams: procedure.dosages
              ? { values: procedure.dosages }
              : undefined,
            minWidth: 150,
          },
        ],
      };

      if (procedure.customDosage) {
        baseColumn.children.push({
          field: `procedure_${procedure.procedure_name.replace(
            /\s+/g,
            "_"
          )}_customDosage`,
          headerName: "Custom Dosage",
          cellRenderer: NoteCellRenderer,
          minWidth: 150,
        });
      }

      if (procedure.hasOwnProperty("note")) {
        baseColumn.children.push({
          field: `procedure_${procedure.procedure_name.replace(
            /\s+/g,
            "_"
          )}_note`,
          headerName: "Note",
          cellRenderer: NoteCellRenderer,
          minWidth: 150,
        });
      }

      return baseColumn;
    });

    const dispensedColumns = sampleOrder.userdispensed.map((dispensed) => {
      const baseColumn = {
        headerName: dispensed.name,
        children: [
          {
            field: `dispensed_${dispensed.name.replace(/\s+/g, "_")}`,
            headerName: "Dosage",
            cellRenderer: DropdownCellRenderer,
            cellRendererParams: { values: dispensed.dosages },
            minWidth: 150,
          },
          {
            field: `dispensed_${dispensed.name.replace(/\s+/g, "_")}_quantity`,
            headerName: "Quantity",
            cellRenderer: QuantityCellRenderer,
            minWidth: 150,
          },
        ],
      };

      if (dispensed.hasOwnProperty("note")) {
        baseColumn.children.push({
          field: `dispensed_${dispensed.name.replace(/\s+/g, "_")}_note`,
          headerName: "Note",
          cellRenderer: NoteCellRenderer,
          minWidth: 150,
        });
      }

      return baseColumn;
    });
    const labColumns = sampleOrder.userLabs.map((lab) => ({
      field: `lab_${lab.test_name.replace(/\s+/g, "_")}`,
      headerName: lab.test_name,
      cellRenderer: CheckboxCellRenderer,
      minWidth: 150,
    }));
    setAllColumnDefs({
      common: commonColumns,
      procedures: procedureColumns,
      dispensed: dispensedColumns,
      labs: labColumns,
    });

    setColumnDefs([
      ...commonColumns,
      ...procedureColumns,
      ...dispensedColumns,
      ...labColumns,
    ]);

    const newRowData = orders.map((order) => {
      const rowData = {
        orderId: order._id,
        date: order.date,
        customName: order.custom || "",
        type: order.type || "Order",
        review: order.review || false,
        status: order.status || "",
        assignees: order.assignees || [],
        room: order.room || "",
        note: order.note || "",
      };

      order.userLabs.forEach((lab) => {
        rowData[`lab_${lab.test_name.replace(/\s+/g, "_")}`] = lab.checked;
      });

      order.userdispensed.forEach((dispensed) => {
        rowData[`dispensed_${dispensed.name.replace(/\s+/g, "_")}`] =
          dispensed.selectedField || "";
        rowData[`dispensed_${dispensed.name.replace(/\s+/g, "_")}_quantity`] =
          dispensed.quantity || 0;
        if (dispensed.hasOwnProperty("note")) {
          rowData[`dispensed_${dispensed.name.replace(/\s+/g, "_")}_note`] =
            dispensed.note || "";
        }
      });

      order.userProcedures.forEach((procedure) => {
        if (procedure.dosages && procedure.dosages.length > 0) {
          rowData[
            `procedure_${procedure.procedure_name.replace(/\s+/g, "_")}`
          ] = procedure.selectedField || "";
        } else {
          rowData[
            `procedure_${procedure.procedure_name.replace(/\s+/g, "_")}`
          ] = procedure.checked;
        }
        if (procedure.customDosage) {
          rowData[
            `procedure_${procedure.procedure_name.replace(
              /\s+/g,
              "_"
            )}_customDosage`
          ] = procedure.customDosage || "";
        }
        if (procedure.hasOwnProperty("note")) {
          rowData[
            `procedure_${procedure.procedure_name.replace(/\s+/g, "_")}_note`
          ] = procedure.note || "";
        }
      });

      return rowData;
    });

    setRowData(newRowData);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    if (allColumnDefs.common) {
      let filteredColumnDefs;
      switch (filter) {
        case "labs":
          filteredColumnDefs = [...allColumnDefs.common, ...allColumnDefs.labs];
          break;
        case "dispensed":
          filteredColumnDefs = [
            ...allColumnDefs.common,
            ...allColumnDefs.dispensed,
          ];
          break;
        case "procedures":
          filteredColumnDefs = [
            ...allColumnDefs.common,
            ...allColumnDefs.procedures,
          ];
          break;
        default:
          filteredColumnDefs = [
            ...allColumnDefs.common,
            ...allColumnDefs.procedures,
            ...allColumnDefs.dispensed,
            ...allColumnDefs.labs,
          ];
      }
      setColumnDefs(filteredColumnDefs);
    }
  }, [filter, allColumnDefs]);

  useEffect(() => {
    if (selectedPatient) {
      const fetchOptions = async () => {
        const assigneeOptions = await fetchAssigneeOptions();
        const roomOptions = await fetchRoomOptions();

        setAllColumnDefs((prevState) => ({
          ...prevState,
          common: prevState.common?.map((col) => {
            if (col.field === "assignees") {
              return {
                ...col,
                cellRendererParams: {
                  ...col.cellRendererParams,
                  values: assigneeOptions,
                },
              };
            }
            if (col.field === "room") {
              return {
                ...col,
                cellRenderer: DropdownCellRenderer,
                cellRendererParams: { values: roomOptions },
              };
            }
            return col;
          }),
        }));
      };

      fetchOptions();
    }
  }, [selectedPatient]);

  return (
    <div style={{ padding: 30 }}>
      <ToastContainer />
      <h1>Treatment View</h1>
      <PatientSearch
        patients={patients}
        onSearch={handleSearch}
        onSelectPatient={handleSelectPatient}
      />
      {selectedPatient && (
        <>
          <UpdatePatient patient={selectedPatient} />
          <div style={{ marginBottom: "20px" }}>
            <Button
              onClick={handleRefresh}
              style={{ marginRight: "10px" }}
              disabled={!selectedPatient}
            >
              Refresh Treatments
            </Button>
            <Button
              onClick={() => createNewOrder("Order")}
              style={{ marginRight: "10px" }}
              disabled={!selectedPatient}
            >
              New Order
            </Button>
            <Button
              onClick={() => createNewOrder("Treatment")}
              disabled={!selectedPatient}
            >
              New Treatment
            </Button>
          </div>
        </>
      )}
      {rowData.length > 0 && (
        <div>
          <h2>Column Filters</h2>
          <ColumnFilters filter={filter} onFilterChange={handleFilterChange} />
          <TreatmentTable
            rowData={rowData}
            columnDefs={columnDefs}
            onCellValueChanged={handleCellValueChanged}
          />
        </div>
      )}
    </div>
  );
};

export default TreatmentView;
